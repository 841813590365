@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reddit+Sans:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Reddit+Sans:ital,wght@0,200..900;1,200..900&display=swap');

html {
    scroll-behavior: smooth;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.font-quicksand {
  font-family: 'Quicksand', sans-serif;
}

.font-hindmadurai {
  font-family: 'Hind Madurai', sans-serif;
}

.font-redditsans {
  font-family: 'Reddit Sans', sans-serif;
}


h2 {
  font-family: 'Poppins';
  font-weight: 200;
  font-size: 38px;
}

h3 {
  font-family: 'Quicksand';
  font-weight: 700;
  font-size: 24px;
}

/* .paragraph {
  font-family: 'reddit sans';
  font-weight: 300;
  font-size: 17px;
} */

p {
  font-family: 'Quicksand';
  font-weight: 300;
  font-size: 17px;
}
  
.text-light {
  color:#dfdfdf;
}

.text-dark {
  color: #1a202c;
}


/* for pop-down menu */
@keyframes popDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes popUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-10px);
    opacity: 0;
  }
}

.pop-down {
  animation: popDown 0.3s forwards;
}

.pop-up {
  animation: popUp 0.3s forwards;
}

/* Ring */

.ring {
  position: relative;
  width: 600px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  --tw-ring-shadow: 0 0 #000 !important;
}

.ring i {
  position: absolute;
  inset: 0;
  border: 2px solid #fff;
  transition: 0.5s;
  z-index: 0;
}

.ring i:nth-child(1) {
  border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
  animation: rotateClockwise 6s linear infinite;
}

.ring i:nth-child(2) {
  border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
  animation: rotateClockwise 4s linear infinite;
}

.ring i:nth-child(3) {
  border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
  animation: rotateCounterClockwise 10s linear infinite;
}

@keyframes rotateClockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotateCounterClockwise {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Animation for fade in when scroll */
.fadein {
  animation: fade-in linear forwards;
  animation-timeline: view();
  animation-range: entry 0px 500px;

}

@keyframes fade-in {
  from {
    opacity: 0;
    scale: 0.8;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}


/* Media Queries for Small Screens for rings */


a:focus {
  background-color: #4a4a4a; /* Change to your desired focus color */
  border-color: #facc15; /* Change to your desired focus border color */
}

/* Portfolio */

.technology {
  font-family: 'Courier New';
  font-size: 14px;
  opacity: 0.5;
  margin-left: 4rem;
  
}