@import url('https://fonts.googleapis.com/css?family=Lato:300,400');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

.title {
  width: 350px;
  position: relative;
  display: flex;
  align-items: center;
  height: 75px;
}

.title .block {
  width: 0%;
  height: inherit;
  background: #CA8A04;
  position: absolute;
  animation: mainBlock 2s cubic-bezier(.74, .06, .4, .92) forwards;
  display: flex;
}

.title h1 {
  font-family: 'Quicksand';
  color: #fff;
  font-size: 52px;
  animation: mainFadeIn 2s forwards;
  animation-delay: 1.6s;
  opacity: 0;
  display: flex;
  align-items: baseline;
  position: relative;
}

.title h1 span {
  width: 0px;
  height: 0px;
  border-radius: 50%;
  background: #CA8A04;
  animation: popIn 0.8s cubic-bezier(.74, .06, .4, .92) forwards;
  animation-delay: 2s;
  margin-left: 5px;
  margin-top: -10px;
  position: absolute;
  bottom: 20px;
  right: -12px;
}

.role {
  width: 350px;
  position: relative;
  display: flex;
  align-items: center;
  height: 35px;
  margin-top: -10px;
}

.role .block {
  width: 0%;
  height: inherit;
  background: #E11D48;
  position: absolute;
  animation: secBlock 2s cubic-bezier(.74, .06, .4, .92) forwards;
  animation-delay: 2s;
  display: flex;
}

.role p {
  animation: secFadeIn 2s forwards;
  animation-delay: 3.2s;
  opacity: 0;
  font-weight: 400;
  font-family: 'Lato';
  color: #ffffff;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 5px;
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: #CA8A04;
    border: 0px solid #ddd;
    opacity: 0;
  }
  50% {
    width: 10px;
    height: 10px;
    background: #CA8A04;
    opacity: 1;
    bottom: 45px;
  }
  65% {
    width: 7px;
    height: 7px;
    bottom: 0px;
    width: 15px;
  }
  80% {
    width: 10px;
    height: 10px;
    bottom: 20px;
  }
  100% {
    width: 7px;
    height: 7px;
    background: #CA8A04;
    border: 0px solid #222;
    bottom: 20 px;
  }
}

@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

/* Media Queries for Small Screens */
@media (max-width: 600px) {
  .title {
    width: 230px;
    height: 50px;
  }

  .title h1 {
    font-size: 32px;
  }

  .title h1 span {
    bottom: 11px;
  }

  .role {
    width: 230px;
    height: 25px;
    margin-top: -5px;
  }

  .role :last-child {
    font-size: 18px;
    letter-spacing: 2px;
  }
}