/* Add this CSS to your Carousel.css file */

.slide-content {
  height: 300px;
  width: 250px;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease;
  flex-shrink: 0;
}

.slide-content-inner {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  border-radius: 0 0 16px 16px;
}

.slide-title {
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: 0.1ch;
  text-transform: uppercase;
}

.slide-center {
  transform: scale(1);
  opacity: 1;
  z-index: 3;
}

.slide-left, .slide-right {
  transform: scale(0.8);
  opacity: 0.8;
  z-index: 2;
  overflow: hidden;
}

.slide-far-left, .slide-far-right {
  transform: scale(0.6);
  opacity: 0.1;
  z-index: 1;
  overflow: hidden;
}

.slide-hidden {
  opacity: 0;
  z-index: 0;
}